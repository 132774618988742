import { graphql, Script } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';
import { Seo } from '../components/Seo';
import { Title } from '../components/Text';

const ContactPage = () => {
	// const addExternalScript = (url: string, callback: GlobalEventHandlers['onload']) => {
	// 	const script = document.createElement('script');
	// 	script.src = url;
	// 	script.async = true;
	// 	script.onload = callback;
	// 	document.body.appendChild(script);
	// };

	return (
		<>
			<Layout>
				<Title level="title">Contact us</Title>
				<Title level="subsection">Meetergo</Title>
				<div
					style={{ minWidth: '500px', height: '620px' }}
					className="meetergo-iframe"
					link="https://my.meetergo.com/martinmeng/hinschg"
				></div>
				<Title level="subsection">Zcal</Title>
				<Script
					type="text/javascript"
					async
					src="https://static.zcal.co/embed/v1/embed.js"
				></Script>
				<div className="zcal-inline-widget">
					<a href="https://zcal.co/onlyknoppas">Schedule a meeting</a>
				</div>
			</Layout>
		</>
	);
};
export default ContactPage;

export const Head = () => {
	return (
		<>
			<Seo title="Kontakt" />
			<script src="https://cdn.jsdelivr.net/gh/meetergo/meetergo-integration/dist/esbuild/browser.js"></script>
		</>
	);
};

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
